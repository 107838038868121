<template>
    <woot-modal :show.sync="show" :on-close="onClose">
      <woot-modal-header
        :header-title="$t('SEND_CONTRACT.TITLE')"
        :header-content="$t('SEND_CONTRACT.DESCRIPTION')"
      />

      <div v-if="isDefaulterResponsibleWithSchool" class="p-8 text-sm text-red-400">
        {{ $t('SEND_CONTRACT.DEFAUTER_CONTACT_TOOLTIP') }}
      </div>

      <contract-contact
        v-if="!isDefaulterResponsibleWithSchool" 
        :is-requesting-contract="uiFlags.isRequestingContract"
        :enrollments="enrollments"
        @cancel="onClose"
        @submit="onSendContract"
      />
    </woot-modal>
  </template>
  
  <script>
  import alertMixin from 'shared/mixins/alertMixin';
  import ContractContact from 'dashboard/modules/contact/components/ContractContact';
  
  import { mapGetters } from 'vuex';
  import { CONTACTS_EVENTS } from '../../helper/AnalyticsHelper/events';
  
  export default {
    components: { ContractContact },
    mixins: [alertMixin],
    props: {
       contact: {
        type: Object,
        default: () => ({}),
    },
      show: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        enrollment: null,
        isSearching: false,
        searchResults: [],
      };
    },
    computed: {
        ...mapGetters({
            enrollments: 'schoolEnrollments/getSchoolEnrollments',
            uiFlags: 'contacts/getUIFlags'
        }),
        contactLabels(){
          return this.$store.getters['contactLabels/getContactLabels'](this.contact.id);
        },
        isDefaulterResponsibleWithSchool(){
          return this.contactLabels.includes('inadimplente_com_a_escola');
        },
    },
    mounted() {
        if (this.contact?.id) {
            this.$store.dispatch('schoolEnrollments/get', this.contact.id);
            this.$store.dispatch('contactLabels/get', this.contact.id);
        }
    },
    methods: {
      onClose() {
        this.$emit('close');
      },
      isEmptyOrNullOrUndefined(value) {
        return value === null || value === undefined || String(value).trim() === '';
      },
      async onSendContract(student) {
        this.$track(CONTACTS_EVENTS.SENDED_CONTRACT);

        if (!student || 
          this.isEmptyOrNullOrUndefined(student.student_name) || 
          this.isEmptyOrNullOrUndefined(student.number) || 
          this.isEmptyOrNullOrUndefined(student.school_contract) || 
          this.isEmptyOrNullOrUndefined(student.id)) {
          this.showAlert(this.$t('SEND_CONTRACT.FORM.ERROR_MESSAGE_EMPTY'));
          return;
        }

        try {
          await this.$store.dispatch('contacts/contract', {
            id: this.contact.id,
            student_id: student.id,
            integration_hook_id: student?.integrationHook?.id
          });
          
          this.showAlert(this.$t('SEND_CONTRACT.FORM.SUCCESS_MESSAGE'));
          this.onClose();
        } catch (error) {
          this.showAlert(this.$t('SEND_CONTRACT.FORM.ERROR_MESSAGE'));
        }
      },
    },
  };
  </script>
<style lang="scss" scoped>

</style>
