<template>
    <div class="h-auto overflow-auto flex flex-col">
        <woot-modal-header :header-title="$t('CAMPAIGN.ADD.TITLE')" :header-content="$t('CAMPAIGN.ADD.DESC')" />
        <form class="flex flex-col w-full" @submit.prevent="addCampaign">
            <div class="w-full">
                <woot-input v-model="title" :label="$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')" type="text"
                    :class="{ error: $v.title.$error }"
                    :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
                    :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')" @blur="$v.title.$touch" />

                <label :class="{ error: $v.selectedInbox.$error }">
                    {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
                    <select v-model="selectedInbox" @change="onChangeInbox($event)">
                        <option v-for="item in inboxes" :key="item.name"
                            :value="{ id: item.id, channel_type: item.channel_type }">
                            {{ item.name }}
                        </option>
                    </select>
                    <span v-if="$v.selectedInbox.$error" class="message">
                        {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
                    </span>
                </label>

                <label v-if="isOngoingType" class="editor-wrap">
                    {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL') }}
                    <woot-message-editor v-model="message" class="message-editor"
                        :class="{ editor_warning: $v.message.$error }"
                        :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')" @blur="$v.message.$touch" />
                    <span v-if="$v.message.$error" class="editor-warning__message">
                        {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
                    </span>
                </label>

                <label v-if="isOnOffType && hasMessage" :class="{ error: $v.message.$error }">
                    {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL') }}
                    <textarea v-model="message" rows="5" type="text"
                        :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')" @blur="$v.message.$touch" />
                    <span v-if="$v.message.$error" class="message">
                        {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
                    </span>
                </label>

                <label v-if="isOnOffType && hasTemplate">
                    {{ $t('CAMPAIGN.ADD.FORM.TEMPLATE_LABEL') }}
                    <templates-picker v-if="!selectedWaTemplate" :inbox-id="selectedInbox.id"
                        @onSelect="toggleWaTemplate" />
                    <template-parser v-else :template="selectedWaTemplate" disableButtons ref="templateParserRef" />
                </label>

                <label v-if="isOnOffType" class="multiselect-wrap--small" :class="{ error: $v.selectedTeam.$error }">
                    {{ $t('CAMPAIGN.ADD.FORM.TEAM.LABEL') }}
                    <multiselect v-model="selectedTeam" track-by="id" label="name" :searchable="false"
                        :placeholder="$t('CAMPAIGN.ADD.FORM.TEAM.PLACEHOLDER')" selected-label
                        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                        :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')" :max-height="160" :options="teamList"
                        :allow-empty="false" :option-height="104" />
                    <span v-if="$v.selectedTeam.$error" class="message">
                        {{ $t('CAMPAIGN.ADD.FORM.TEAM.ERROR') }}
                    </span>
                </label>

                <label v-if="isOnOffType" class="multiselect-wrap--small"
                    :class="{ error: $v.selectedAudience.$error }">
                    {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.LABEL') }}
                    <multiselect v-model="selectedAudience" :options="audienceList" track-by="id" label="title"
                        :multiple="true" :close-on-select="false" :clear-on-select="false" :hide-selected="true"
                        :placeholder="$t('CAMPAIGN.ADD.FORM.AUDIENCE.PLACEHOLDER')" selected-label
                        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                        :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')" @blur="$v.selectedAudience.$touch"
                        @select="$v.selectedAudience.$touch" />
                    <span v-if="$v.selectedAudience.$error" class="message">
                        {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
                    </span>
                </label>

                <div v-if="isOnOffType" >
                    <label class="multiselect-wrap--small"
                        :class="{ error: $v.defaulterStatus.$error }">
                        {{ $t('CAMPAIGN.ADD.FORM.DEFAULTER_STATUS.LABEL') }}
                    </label>
                    <multiselect v-model="defaulterStatus"  track-by="value" label="label"
                        :preselect-first="true" selected-label 
                        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                        :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')" :max-height="160" :options="defaulterStatusList"
                        :allow-empty="true" :option-height="104"/>
                </div>

                <label v-if="isOngoingType" :class="{ error: $v.selectedSender.$error }">
                    {{ $t('CAMPAIGN.ADD.FORM.SENT_BY.LABEL') }}
                    <select v-model="selectedSender">
                        <option v-for="sender in sendersAndBotList" :key="sender.name" :value="sender.id">
                            {{ sender.name }}
                        </option>
                    </select>
                    <span v-if="$v.selectedSender.$error" class="message">
                        {{ $t('CAMPAIGN.ADD.FORM.SENT_BY.ERROR') }}
                    </span>
                </label>

                <label v-if="isOnOffType">
                    {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
                    <woot-date-time-picker :value="scheduledAt"
                        :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
                        :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')" @change="onChange" />
                </label>

                <woot-input v-if="isOngoingType" v-model="endPoint" :label="$t('CAMPAIGN.ADD.FORM.END_POINT.LABEL')"
                    type="text" :class="{ error: $v.endPoint.$error }" :error="$v.endPoint.$error ? $t('CAMPAIGN.ADD.FORM.END_POINT.ERROR') : ''
                        " :placeholder="$t('CAMPAIGN.ADD.FORM.END_POINT.PLACEHOLDER')" @blur="$v.endPoint.$touch" />
                <woot-input v-if="isOngoingType" v-model="timeOnPage"
                    :label="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.LABEL')" type="text"
                    :class="{ error: $v.timeOnPage.$error }" :error="$v.timeOnPage.$error
                        ? $t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.ERROR')
                        : ''
                        " :placeholder="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.PLACEHOLDER')"
                    @blur="$v.timeOnPage.$touch" />
                <label v-if="isOngoingType">
                    <input v-model="enabled" type="checkbox" value="enabled" name="enabled" />
                    {{ $t('CAMPAIGN.ADD.FORM.ENABLED') }}
                </label>
                <label v-if="isOngoingType">
                    <input v-model="triggerOnlyDuringBusinessHours" type="checkbox"
                        value="triggerOnlyDuringBusinessHours" name="triggerOnlyDuringBusinessHours" />
                    {{ $t('CAMPAIGN.ADD.FORM.TRIGGER_ONLY_BUSINESS_HOURS') }}
                </label>
            </div>

            <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
                <woot-button :is-loading="uiFlags.isCreating">
                    {{ $t('CAMPAIGN.ADD.CREATE_BUTTON_TEXT') }}
                </woot-button>
                <woot-button variant="clear" @click.prevent="onClose">
                    {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
                </woot-button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import campaignMixin from 'shared/mixins/campaignMixin';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import { URLPattern } from 'urlpattern-polyfill';
import { CAMPAIGNS_EVENTS } from '../../../../../helper/AnalyticsHelper/events';
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker.vue';
import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParser.vue';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import configMixin from 'shared/mixins/configMixin';

export default {
    components: {
        WootDateTimePicker,
        WootMessageEditor,
        TemplatesPicker,
        TemplateParser
    },

    mixins: [alertMixin, campaignMixin, configMixin],
    data() {
        return {
            title: '',
            message: '',
            selectedSender: 0,
            selectedInbox: null,
            endPoint: '',
            timeOnPage: 10,
            show: true,
            enabled: true,
            triggerOnlyDuringBusinessHours: false,
            scheduledAt: null,
            selectedAudience: [],
            senderList: [],
            selectedWaTemplate: null,
            selectedTeam: null,
            defaulterStatus: null
        };
    },

    validations() {
        let commonValidations = {
            title: {
                required,
            },
            selectedInbox: {
                required,
            },
            defaulterStatus: {}
        };
        if (this.hasMessage) {
            commonValidations = { ...commonValidations, message: { required } }
        }

        if (this.isOnOffType) {
            commonValidations = { ...commonValidations, selectedTeam: { required } }
        }

        if (this.hasTemplate) {
            commonValidations = { ...commonValidations, selectedWaTemplate: { required } }
        }
        if (this.isOngoingType) {
            commonValidations = {
                ...commonValidations,
                selectedSender: {
                    required,
                },
                endPoint: {
                    required,
                    shouldBeAValidURLPattern(value) {
                        try {
                            // eslint-disable-next-line
                            new URLPattern(value);
                            return true;
                        } catch (error) {
                            return false;
                        }
                    },
                    shouldStartWithHTTP(value) {
                        if (value) {
                            return (
                                value.startsWith('https://') || value.startsWith('http://')
                            );
                        }
                        return false;
                    },
                },
                timeOnPage: {
                    required,
                },
            };
        } else {
            commonValidations = {
                ...commonValidations,
                selectedAudience: {
                    isEmpty() {
                        return !!this.selectedAudience.length;
                    },
                },
            }
        }
        return commonValidations;
    },
    computed: {
        ...mapGetters({
            uiFlags: 'campaigns/getUIFlags',
            contactLabels: 'labels/getLabels',
            schoolLabels: 'labels/getSchoolLabels',
            teams: 'teams/getTeams',
        }),
        audienceList() {
            let labelsList = this.contactLabels

            labelsList = labelsList.concat({
                id: `school_label_section`,
                title: this.$t('CAMPAIGN.ADD.FORM.AUDIENCE.SCHOOL_SECTION.LABEL'),
                $isDisabled: true,
            });
            labelsList = labelsList.concat(this.schoolLabels);

            return labelsList;
        },
        teamList() {
            const empty_option = {
                id: null,
                name: this.$t('CAMPAIGN.ADD.FORM.TEAM.ALL_OPTION')
            }
            return [empty_option, ...this.teams]
        },
        inboxes() {
            if (this.isOngoingType) {
                return this.$store.getters['inboxes/getWebsiteInboxes'];
            }
            return this.$store.getters['inboxes/getOnOffCampaignInboxes'];
        },
        sendersAndBotList() {
            return [
                {
                    id: 0,
                    name: 'Bot',
                },
                ...this.senderList,
            ];
        },
        hasTemplate() {
            return this.selectedInbox && [INBOX_TYPES.WHATSAPP].includes(this.selectedInbox.channel_type)
        },
        hasMessage() {
            return this.isOngoingType || (this.selectedInbox && [INBOX_TYPES.SMS, INBOX_TYPES.TWILIO].includes(this.selectedInbox.channel_type))
        },
        defaulterStatusList() {
            return this.$t('CAMPAIGN.ADD.FORM.DEFAULTER_STATUS.OPTIONS') && Object.entries(this.$t('CAMPAIGN.ADD.FORM.DEFAULTER_STATUS.OPTIONS')).map(([key, value]) => ({
                value: key,
                label: value
            }));
        }
    },
    mounted() {
        this.$track(CAMPAIGNS_EVENTS.OPEN_NEW_CAMPAIGN_MODAL, {
            type: this.campaignType,
        });
    },
    methods: {
        onClose() {
            this.$emit('on-close');
        },
        onChange(value) {
            this.scheduledAt = value;
        },
        async onChangeInbox() {
            try {
                const response = await this.$store.dispatch('inboxMembers/get', {
                    inboxId: this.selectedInbox.id,
                });
                const {
                    data: { payload: inboxMembers },
                } = response;
                this.senderList = inboxMembers;
            } catch (error) {
                const errorMessage =
                    error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
                this.showAlert(errorMessage);
            }
        },
        getTemplateInfo() {
            const templateParserRef = this.$refs.templateParserRef;
            const templateParserPayload = templateParserRef.getTemplatePayload();

            let templateInfos = templateParserPayload.templateParams;

            if (templateParserRef.attachedFile) {
                templateInfos = { ...templateInfos, attachments: [templateParserRef.attachedFile.resource] }
            }

            if (templateParserPayload?.additionalAttributes) {
                templateInfos = { ...templateInfos, additional_attributes: templateParserPayload?.additionalAttributes }
            }

            return templateInfos;
        },
        getTemplateMessage() {
            const templateParserRef = this.$refs.templateParserRef;
            return templateParserRef.processedString
        },
        getCampaignDetails() {
            let campaignDetails = null;
            if (this.isOngoingType) {
                campaignDetails = {
                    title: this.title,
                    message: this.message,
                    inbox_id: this.selectedInbox.id,
                    sender_id: this.selectedSender || null,
                    enabled: this.enabled,
                    trigger_only_during_business_hours:
                        // eslint-disable-next-line prettier/prettier
                        this.triggerOnlyDuringBusinessHours,
                    trigger_rules: {
                        url: this.endPoint,
                        time_on_page: this.timeOnPage,
                    },
                };
            } else {
                const audience = this.selectedAudience.map(item => {
                    return {
                        id: item.id,
                        type: 'Label',
                    };
                });
                campaignDetails = {
                    title: this.title,
                    message: this.hasTemplate ? this.getTemplateMessage() : this.message,
                    template_info: this.getTemplateInfo(),
                    inbox_id: this.selectedInbox.id,
                    team_id: this.selectedTeam.id,
                    scheduled_at: this.scheduledAt,
                    defaulter_status: this.defaulterStatus?.value,
                    audience,
                };
            }
            return campaignDetails;
        },
        async addCampaign() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            try {
                const campaignDetails = this.getCampaignDetails();
                await this.$store.dispatch('campaigns/create', campaignDetails);
                await this.$store.dispatch('labels/get');

                // tracking this here instead of the store to track the type of campaign
                this.$track(CAMPAIGNS_EVENTS.CREATE_CAMPAIGN, {
                    type: this.campaignType,
                });
                this.showAlert(this.$t('CAMPAIGN.ADD.API.SUCCESS_MESSAGE'));
                this.onClose();
            } catch (error) {
                const errorMessage =
                    error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
                this.showAlert(errorMessage);
            }
        },
        toggleWaTemplate(template) {
            this.selectedWaTemplate = template;
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
    height: 5rem;
}

.message-editor {
    @apply px-3;

    ::v-deep {
        .ProseMirror-menubar {
            @apply rounded-tl-[4px];
        }
    }
}
</style>