<template>
  <form class="contact--form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="columns">
        <woot-avatar-uploader :label="$t('SCHOOL_CONTACT_FORM.FORM.AVATAR.LABEL')" :src="avatarUrl"
          :username-avatar="name" :delete-avatar="!!avatarUrl" class="settings-item" @change="handleImageUpload"
          @onAvatarDelete="handleAvatarDelete" />
      </div>
    </div>

    <div class="row">
      <div class="columns">
        <label :class="{ error: $v.name.$error }">
          {{ $t('SCHOOL_CONTACT_FORM.FORM.NAME.LABEL') }}
          <input v-model.trim="name" type="text" :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.NAME.PLACEHOLDER')"
            @input="$v.name.$touch" />
          <span v-if="$v.name.$error" class="message">
            {{ $t('SCHOOL_CONTACT_FORM.FORM.NAME.ERROR') }}
          </span>
        </label>

        <div class="row" v-if="contact && contact.id">
          <label>
            {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_LABEL.LABEL') }}
          </label>
          <multiselect v-model="selectedSchoolLabel" track-by='title' label="title" :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_LABEL.PLACEHOLDER')"
            selected-label :select-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_LABEL.SELECT_PLACEHOLDER')" :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_LABEL.REMOVE')"
            :max-height="160" :options="schoolLabelsOptions" :allow-empty="true" :option-height="104" @select="onSelectLabel">

            <template slot="singleLabel" slot-scope="props">
              <div class="label-option">
                <div v-if="props.option.color" class="label-color--display"
                  :style="{ backgroundColor: props.option.color }" />
                <span class="label-text" :title="props.option.title">{{ props.option.title }}</span>
              </div>
            </template>

            <template slot="option" slot-scope="props">
              <div class="label-option">
                <div v-if="props.option.color" class="label-color--display"
                  :style="{ backgroundColor: props.option.color }" />
                <span class="label-text" :title="props.option.title">{{ props.option.title }}</span>
              </div>
            </template>

          </multiselect>
        </div>

        <label :class="{ error: $v.email.$error }">
          {{ $t('SCHOOL_CONTACT_FORM.FORM.EMAIL_ADDRESS.LABEL') }}
          <input v-model.trim="email" type="text" :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EMAIL_ADDRESS.PLACEHOLDER')"
            @input="$v.email.$touch" />
          <span v-if="$v.email.$error" class="message">
            {{ emailError }}
          </span>
        </label>
      </div>
    </div>
    <div class="medium-12 columns">
      <label :class="{ error: $v.description.$error }">
        {{ $t('CONTACT_FORM.FORM.BIO.LABEL') }}
        <textarea v-model.trim="description" type="text" :placeholder="$t('CONTACT_FORM.FORM.BIO.PLACEHOLDER')"
          @input="$v.description.$touch" />
      </label>
    </div>
    <div class="row">
      <div class="medium-12 columns">
        <label :class="{
          error: $v.phoneNumber.$error,
        }">
          {{ $t('SCHOOL_CONTACT_FORM.FORM.PHONE_NUMBER.LABEL') }}
          <woot-phone-input v-model="phoneNumber" :value="phoneNumber"
            :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')" 
            @input="onPhoneNumberInputChange" @blur="$v.phoneNumber.$touch" @setCode="setPhoneCode" 
            v-mask="'###########'"/>
          <span v-if="$v.phoneNumber.$error" class="message">
            {{ phoneNumberError }}
          </span>
        </label>
        <div v-if="!isValidPhoneNumber || !phoneNumber" class="callout small warning">
          <span v-dompurify-html="$t('SCHOOL_CONTACT_FORM.FORM.PHONE_NUMBER.HELP')" />
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="medium-12 columns">
        <woot-submit-button :loading="inProgress" :button-text="$t('SCHOOL_CONTACT_FORM.FORM.SUBMIT')" />
        <button class="button clear" @click.prevent="onCancel">
          {{ $t('SCHOOL_CONTACT_FORM.FORM.CANCEL') }}
        </button>
      </div>
    </div>
  </form>
</template>
  
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import {
  DuplicateContactException,
  ExceptionWithMessage,
} from 'shared/helpers/CustomErrors';
import { required, email } from 'vuelidate/lib/validators';
import { isPhoneNumberValid, isBrasilPhoneNumberValid } from 'shared/helpers/Validators';
import parsePhoneNumber from 'libphonenumber-js';
import { isPersonNameValid } from 'shared/helpers/KeduValidators';

export default {
  mixins: [alertMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => { },
    },
    selectedSchoolLabelsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      description: '',
      email: '',
      name: '',
      selectedSchoolLabel: '',
      phoneNumber: '',
      activeDialCode: '',
      avatarFile: null,
      avatarUrl: '',
      additionalAttributes: {},
    };
  },
  validations() {
    return {
      description: {},
      name: { required, keduValidation: this.isNameNotValid},
      email: { email, emailOrPhoneRequired: () => this.hasEmailOrPhone},
      phoneNumber: { emailOrPhoneRequired: () => this.hasEmailOrPhone, phoneNumber: () => this.isValidPhoneNumber},
    };
  },
  computed: {
    ...mapGetters({
      schoolLabels: 'labels/getSchoolLabels',
      enrollments: 'schoolEnrollments/getSchoolEnrollments'
     }),
    schoolLabelsOptions(){
      return this.schoolLabels.filter(
        label => !this.disabledLabels.includes(label.title)
      );
    },
    disabledLabels(){
      return [
        '10_assinatura_de_contrato',
        '11_matrícula',
        '12_contrato_recusado',
        '13_matrícula_manual',
        '14_contrato_parcial',
      ];
    },
    isNameNotValid(){
      return value => isPersonNameValid(value);
    },
    hasEmailOrPhone(){
      return this.email.length > 0 || this.phoneNumber.length > 0
    },
    parsePhoneNumber() {
      return parsePhoneNumber(this.phoneNumber);
    },

    isValidPhoneNumber() {
      if (this.activeDialCode === '' && this.phoneNumber.length > 0){
        return false;
      }
      if (this.activeDialCode !== '' && this.phoneNumber.length === 0){
        return false;
      }
      if (this.phoneNumber.length > 0 && !isBrasilPhoneNumberValid(this.phoneNumber, this.activeDialCode)){
        return false;
      }
      return true;
    },
    phoneNumberError() {
      if (this.email.length === 0 && this.phoneNumber.length === 0){
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER_OR_EMAIL_REQUIRED_ERROR');
      }
      if (this.activeDialCode === '') {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DIAL_CODE_ERROR');
      }
      if (!isBrasilPhoneNumberValid(this.phoneNumber, this.activeDialCode)){
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.INVALID_NUMBER_IN_COUNTRY');
      }
      if (!isPhoneNumberValid(this.phoneNumber, this.activeDialCode)) {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR');
      }
      return '';
    },
    emailError() {
      if (this.email.length === 0 && this.phoneNumber.length === 0){
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER_OR_EMAIL_REQUIRED_ERROR');
      }
      return this.$t('SCHOOL_CONTACT_FORM.FORM.EMAIL_ADDRESS.ERROR')
    },
    setPhoneNumber() {
      if (this.parsePhoneNumber && this.parsePhoneNumber.countryCallingCode) {
        return this.phoneNumber;
      }
      if (this.phoneNumber === '' && this.activeDialCode !== '') {
        return '';
      }
      return this.activeDialCode
        ? `${this.activeDialCode}${this.phoneNumber}`
        : '';
    },
  },
  watch: {
    contact() {
      this.setContactObject();
    },
  },
  mounted() {
    this.setContactObject();
    this.setDialCode();
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    phoneMask(){
      return this.phoneNumber.length === 10 ? '(##) # ####-####' : '(##) # ####-####';
    },
    countryNameWithCode({ name, id }) {
      if (!id) return name;
      if (!name && !id) return '';
      return `${name} (${id})`;
    },
    setDialCode() {
      if (
        this.phoneNumber !== '' &&
        this.parsePhoneNumber &&
        this.parsePhoneNumber.countryCallingCode
      ) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        this.activeDialCode = `+${dialCode}`;
      }
    },
    setContactObject() {
      const {
        email: emailAddress,
        phone_number: phoneNumber,
        school_contact,
        name,
        additional_attributes: additionalAttributes,
      } = this.contact;

      this.additionalAttributes = additionalAttributes || {};
      this.name = name || '';
      this.email = emailAddress || '';
      this.phoneNumber = phoneNumber || '';
      this.description = additionalAttributes?.description || '';
      this.avatarUrl = this.contact.thumbnail || '';

      this.selectedSchoolLabel = school_contact?.school_label_list[0] && 
      this.schoolLabels.find(label => label.title === school_contact.school_label_list[0]) || this.schoolLabels[0];
    },
    getContactObject() {
      if (this.state === null) {
        this.state = {
          id: '',
          name: '',
        }
      }
      const contactObject = {
        id: this.contact.id,
        name: this.name,
        email: this.email,
        phone_number: this.setPhoneNumber,
        additional_attributes: {
          ...this.contact.additional_attributes,
          description: this.description,
        },
      };
      if (this.avatarFile) {
        contactObject.avatar = this.avatarFile;
        contactObject.isFormData = true;
      }

      if(!this.contact?.id) {
        contactObject.school_label_list = ['00_contato_criado'];
      }

      return contactObject;
    },
    onPhoneNumberInputChange(value, code) {
      this.activeDialCode = code;
    },
    setPhoneCode(code) {
      if (this.phoneNumber !== '' && this.parsePhoneNumber) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        if (dialCode === code) {
          return;
        }
        this.activeDialCode = `+${dialCode}`;
        const newPhoneNumber = this.phoneNumber.replace(
          `+${dialCode}`,
          `${code}`
        );
        this.phoneNumber = newPhoneNumber;
      } else {
        this.activeDialCode = code;
      }
    },
    hasLeadLabel() {
      return this.hasIncludedLabel("lead") || this.hasIncludedLabel("02_lead");
    },
    hasDataFinalizationLabel() {
      return this.hasIncludedLabel("09_finalização_de_dados") || this.hasIncludedLabel("10_assinatura_de_contrato") || this.hasIncludedLabel("11_matrícula") || this.hasIncludedLabel("12_contrato_recusado") || this.hasIncludedLabel("13_matrícula_manual") || this.hasIncludedLabel("14_contrato_parcial");
    },
    hasIncludedLabel(label) {
      return this.selectedSchoolLabelsList.includes(label);
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        await this.onSubmit(this.getContactObject());
        this.onSuccess();
        this.showAlert(this.$t('CONTACT_FORM.SUCCESS_MESSAGE'));
      } catch (error) {
        if (error instanceof DuplicateContactException) {
          if (error.data.includes('email')) {
            this.showAlert(
              this.$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.DUPLICATE')
            );
          } else if (error.data.includes('phone_number')) {
            this.showAlert(this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DUPLICATE'));
          }
        } else if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('CONTACT_FORM.ERROR_MESSAGE'));
        }
      }
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async handleAvatarDelete() {
      try {
        if (this.contact && this.contact.id) {
          await this.$store.dispatch('contacts/deleteAvatar', this.contact.id);
          this.showAlert(
            this.$t('CONTACT_FORM.DELETE_AVATAR.API.SUCCESS_MESSAGE')
          );
        }
        this.avatarFile = null;
        this.avatarUrl = '';
        this.activeDialCode = '';
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('CONTACT_FORM.DELETE_AVATAR.API.ERROR_MESSAGE')
        );
      }
    },
    async onSelectLabel(label) {
      if (this.contact) {
        try {
          await this.$store.dispatch('contacts/update', { ...this.contact, school_label_list: [label.title] });
          this.showAlert(
            this.$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_LABEL.SUCCESS_MESSAGE')
          );
        } catch (error) {
          this.showAlert(
            this.$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_LABEL.ERROR_MESSAGE')
          );
        }
      }
    }
  }
};
</script>
  
<style scoped lang="scss">
.contact--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);

  .columns {
    padding: 0 var(--space-smaller);
  }
}

.input-group-label {
  font-size: var(--font-size-small);
}

::v-deep {
  .multiselect .multiselect__tags .multiselect__single {
    padding-left: 0;
  }
}

.label-color--display {
  border-radius: var(--border-radius-normal);
  height: var(--space-slab);
  margin-right: var(--space-smaller);
  margin-top: var(--space-micro);
  min-width: var(--space-slab);
  width: var(--space-slab);
  border: 1px solid var(--color-border-light);
}

.label-option {
  display: flex;
  align-items: center;

}
</style>
  